body {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.main-container {
  margin-bottom: 5em !important;
}

/* If the screen size is 992px wide or more (Bootstrap lg and larger) */
@media (min-width: 992px) {
  .file-list-widget {
    font-size: 1em;
  }
}

/* If the screen size is smaller than 992px and larger than 768px (Bootstrap lg - md */
@media (max-width: 991.98px) and (min-width: 768px) {
  .file-list-widget {
    font-size: 0.7em;
  }
}

/* If the screen size is smaller than 768px (Bootstrap md and smaller) */
@media (max-width: 767.98px) {
  .file-list-widget {
    font-size: 0.6em;
  }
}

.italic {
  font-style: italic;
}

.grey-text {
  color: #989898;
}

.footer {
  font-size: 0.6em;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-top: 2em;
  padding-bottom: 2em;
  background-color: #F5F5F5;
  text-align: center;
}

.btn.btn-primary {
  background-color: #7da036;
  border: none;
}

.btn.btn-primary:hover {
  background-color: #668518;
  border: none;
}

.badge.bg-primary {
  background-color: #7da036 !important;
}

.badge.bg-secondary {
  background-color: #ffffff !important;
  border: 2px solid #7da036;
  color: #7da036;
  font-weight: bolder;
}

.text-12 {
  font-size: 12px;
}

.text-14 {
  font-size: 14px;
}

.text-16 {
  font-size: 16px;
}

.auth-logo {
  width: 143px;
  height: 143px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.auth-method-icon {
  height: 24px;
  width: 24px;
  padding: 0;
  margin-left: 24px;
  margin-right: 24px;
}

.dark-on-hover:hover {
  background-color: rgb(231, 231, 231);
}

.essential-information-table {
  width: auto !important;
  margin-left: 4em;
  margin-right: 4em;
  margin-top: 1em;
}

.essential-information-table td {
  width: 50%;
}

.travel-documents {
  padding-top: 1em;
}

.file-icon-column {
  width: 4em;
}

/* If the screen size is larger than 768px (above Bootstrap md) */
@media (min-width: 768px) {
  .filename-column {
    width: 40%;
  }
}


/* If the screen size is smaller than 768px (Bootstrap md and smaller) */
@media (max-width: 767.98px) {
  .filename-column {
    width: 60%;
  }
}

.file-icon {
  margin-left: 36px;
}

.download-button {
  width: 5em;
}
.download-all-button {
  padding: 0em 1.5em !important;
}

.bottom-row {
  width:auto !important;
}

.black-text {
  color: #000000 !important;
}

.no-text-decoration {
  text-decoration: none !important;
}

.underline-on-hover:hover {
  text-decoration: underline !important;
}

.token-input-wrapper {
  width: 100%;
  height: 4em;
  max-width: 100%;
  position: relative;
}

.token-input {
  width: 100%;
  height: 100%;
}

.token-input-button {
  position: absolute;
  width: 4em;
  height: 2em;
  border-radius: 500px !important;
  right: 10px;
  top: 0px;
  transform: translateY(25%);
}

.token-input-button svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.back-to-auth-arrow {
  margin-right: 16px;
}

.min-width-300 {
  min-width: 300px;
}
